import { useEffect, useState } from 'react';

const MOBILE_WIDTH = 768;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const changeSize = () => {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };
    window.addEventListener('resize', changeSize);

    return () => window.removeEventListener('resize', changeSize);
  }, []);

  return isMobile;
};
