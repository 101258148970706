/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Link from 'next/link';
import { useState } from 'react';
import Div100vh from 'react-div-100vh';
import styled from 'styled-components';

import { useIsMobile } from '@/hooks/useIsMobile';
import useScrollDirection from '@/hooks/useScrollDirection';
import { BREAKPOINTS } from '@/theme';

import { Container } from './Container';
import Logo from './Logo';

type THeader = {
  theme: 'Light' | 'Dark';
};

const StyledHeader = styled.header`
  transition: all 0.3s ease-in;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 900;
  padding: 30px 0;

  &.scroll-down {
    transform: translateY(-100%);
  }

  &.scroll-up {
    transform: translateY(0);
  }

  &.active {
    transform: translateY(0);
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHamburger = styled.div<{ themeMode: string }>`
  display: block;
  height: 23px;
  width: 28px;
  z-index: 2;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.4s ease-in;

  span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: var(--grey);
    transition: background-color 0.3s ease-in;

    ${(p) => p.themeMode === 'Dark' && `background-color: white`}
  }

  &.active {
    transform: rotate(45deg);

    span {
      background-color: white;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    height: 19px;
    width: 24px;
  }
`;

const StyledNav = styled(Div100vh)`
  background-color: var(--grey);
  position: fixed;
  width: 100vw;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  will-change: opacity;
  color: var(--white);
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in, visibility 0s ease-in 0.4s;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: var(--white);
    text-decoration: none;
    transform: translateY(100%);
    display: inline-block;
    transition: all 0.6s ease-in;
    transition-delay: 0.1s;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 0%;
      height: 4px;
      background-color: var(--white);
      bottom: 0;
      right: 0;
      transition: all 0.4s ease-out;
    }

    &:hover {
      &::after {
        width: 100%;
        right: auto;
        left: 0;
      }
    }
  }

  li {
    position: relative;
    display: block;
    overflow: hidden;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in, visibility 0s ease-in 0s;

    a {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    align-items: flex-start;
    padding-top: 120px;
  }
`;

const StyledNavInner = styled.div`
  width: 100%;
`;

const StyledNavFooter = styled.div`
  width: 100%;
  position: absolute;
  bottom: 60px;
  text-align: right;

  a {
    transform: none;
  }

  a::after {
    height: 1px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    text-align: left;
    font-size: 14px;
    bottom: 40px;
  }
`;

const StyledNavFooterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 28px;
`;

const StyledContact = styled.div`
  display: flex;
  margin-top: 10px;

  a {
    display: block;
    text-decoration: underline;
    font-weight: 600;

    &:first-child {
      margin-right: 20px;
    }
  }
`;

const Header = ({ theme }: THeader) => {
  const [navActive, setNavActive] = useState(false);

  const scrollDirection = useScrollDirection();
  const isMobile = useIsMobile();

  return (
    <StyledHeader className={`scroll-${scrollDirection} ${navActive ? 'active' : ''}`}>
      <Container size="wide">
        <StyledRow>
          <Link href="/" passHref>
            <a>
              <Logo theme={theme} />
            </a>
          </Link>
          <StyledHamburger
            themeMode={theme}
            onClick={() => setNavActive(!navActive)}
            className={`${navActive ? 'active' : ''}`}
          >
            <span />
            <span />
            <span />
          </StyledHamburger>
        </StyledRow>
      </Container>
      <StyledNav className={`${navActive ? 'active' : ''} ${theme === 'Dark' ? 'dark' : 'light'}`}>
        <StyledNavInner>
          <Container>
            <ul>
              <li>
                <Link href="/#work">
                  <a className="display" onClick={() => setNavActive(false)}>
                    Work
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/about">
                  <a className="display" onClick={() => setNavActive(false)}>
                    About
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/services">
                  <a className="display" onClick={() => setNavActive(false)}>
                    Services
                  </a>
                </Link>
              </li>
            </ul>
          </Container>
        </StyledNavInner>

        {isMobile ? (
          <StyledNavFooter>
            <Container size="wide">
              <strong>New Business</strong>
              <br />
              Team up and talk to us on
              <StyledContact>
                <a href="tel:+447973830266">07973 830 266</a>
                <a href="mailto:nicholas.gill@teameleven.co.uk">Email</a>
              </StyledContact>
            </Container>
          </StyledNavFooter>
        ) : (
          <StyledNavFooter>
            <Container size="wide">
              <StyledNavFooterRow>
                <strong>New Business </strong>
                <span>07973 830 266</span>
                <a href="mailto:nicholas.gill@teameleven.co.uk">nicholas.gill@teameleven.co.uk</a>
              </StyledNavFooterRow>
            </Container>
          </StyledNavFooter>
        )}
      </StyledNav>
    </StyledHeader>
  );
};

export default Header;
