import styled from 'styled-components';

type TLogo = {
  theme: 'Light' | 'Dark';
};

const StyledLogo = styled.div<{ colour: string }>`
  svg {
    width: 155px;
    height: auto;
    display: block;
    path {
      ${(p) => p.colour === 'Light' && `fill: #3C3B3B`}
      ${(p) => p.colour === 'Dark' && `fill: #FFFFFF`}
    }
  }
`;

const Logo = ({ theme }: TLogo) => {
  return (
    <StyledLogo colour={theme}>
      <svg viewBox="0 0 227 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title id="teamElevenLogoTitle">Team Eleven</title>
        <path d="M21.626 0L0 21.626L5.294 26.926L13.237 18.983L29.603 35.346L35.346 29.6L18.98 13.238L26.922 5.3L21.626 0Z" />
        <path d="M23.1465 13.0049L37.5465 27.4049L43.2885 21.6629L28.8885 7.26294L23.1465 13.0049Z" />
        <path d="M7.26172 28.889L21.6617 43.289L27.4037 37.547L13.0037 23.147L7.26172 28.889Z" />
        <path d="M57.8496 10.6841V14.4711H64.2756V32.0711H68.7756V14.4711H75.1756V10.6841H57.8496Z" />
        <path d="M84.4404 16.5752C85.5548 17.0793 86.5025 17.8907 87.1724 18.9142C87.8364 19.8949 88.2673 21.0144 88.4324 22.1872C88.5591 23.2284 88.6046 24.2779 88.5684 25.3262H77.0524C77.0176 25.9776 77.1514 26.627 77.441 27.2116C77.7306 27.7961 78.1661 28.2961 78.7054 28.6632C79.393 29.075 80.1842 29.2815 80.9854 29.2582C81.8137 29.2966 82.6288 29.0397 83.2854 28.5332C83.6389 28.231 83.9283 27.8611 84.1364 27.4452H88.3574C88.1646 28.5365 87.6257 29.5368 86.8204 30.2982C86.0519 31.0864 85.123 31.7003 84.0967 32.0984C83.0703 32.4964 81.9704 32.6692 80.8714 32.6052C78.8436 32.632 76.8713 31.9429 75.3014 30.6592C73.6927 29.3618 72.8884 27.2515 72.8884 24.3282C72.8117 23.1858 72.9649 22.0396 73.339 20.9575C73.7131 19.8754 74.3005 18.8793 75.0664 18.0282C75.8087 17.2954 76.6935 16.7228 77.666 16.3458C78.6386 15.9687 79.6781 15.7953 80.7204 15.8362C81.9988 15.8158 83.2669 16.0677 84.4404 16.5752M78.2544 20.1472C77.6359 20.8249 77.2505 21.6827 77.1544 22.5952H84.2774C84.2737 22.1256 84.1743 21.6616 83.9851 21.2318C83.796 20.8019 83.5211 20.4151 83.1774 20.0952C82.4933 19.5182 81.6199 19.2147 80.7254 19.2432C80.2742 19.215 79.822 19.2804 79.3973 19.4353C78.9726 19.5902 78.5845 19.8312 78.2574 20.1432" />
        <path d="M97.8711 22.4503C98.4348 22.401 98.9898 22.279 99.5221 22.0873C99.7765 22.012 100 21.8574 100.16 21.646C100.321 21.4346 100.409 21.1775 100.413 20.9123C100.44 20.6465 100.39 20.3786 100.269 20.1406C100.147 19.9026 99.96 19.7046 99.7291 19.5703C99.0991 19.289 98.4109 19.1628 97.7221 19.2023C97.3403 19.163 96.9546 19.2077 96.5919 19.3331C96.2291 19.4585 95.8982 19.6616 95.6221 19.9283C95.3006 20.351 95.0976 20.8519 95.0341 21.3793H91.0441C91.076 20.1502 91.4817 18.96 92.2071 17.9673C93.3051 16.5746 95.1895 15.8783 97.8601 15.8783C99.4648 15.848 101.053 16.2011 102.494 16.9083C103.844 17.5949 104.519 18.8909 104.519 20.7963V28.0493C104.519 28.5519 104.529 29.1613 104.548 29.8773C104.538 30.2587 104.623 30.6368 104.795 30.9773C104.952 31.1879 105.162 31.3532 105.404 31.4563V32.0653H100.904C100.787 31.7749 100.699 31.4733 100.643 31.1653C100.595 30.8853 100.556 30.5663 100.527 30.2083C99.9501 30.8319 99.2831 31.3658 98.5481 31.7923C97.6224 32.3127 96.575 32.578 95.5131 32.5613C94.2131 32.6098 92.9416 32.1728 91.9461 31.3353C91.4676 30.8973 91.0929 30.3581 90.8493 29.7569C90.6057 29.1557 90.4994 28.5078 90.5381 27.8603C90.4795 27.0152 90.6606 26.1708 91.0606 25.4241C91.4606 24.6774 92.0632 24.0587 92.7991 23.6393C93.9407 23.0743 95.1788 22.7299 96.4481 22.6243L97.8711 22.4503ZM100.399 24.3803C100.146 24.5396 99.8783 24.6736 99.5991 24.7803C99.2378 24.9058 98.8665 25.0005 98.4891 25.0633L97.5481 25.2353C96.8884 25.3209 96.2469 25.5124 95.6481 25.8023C95.3362 25.9727 95.0788 26.2278 94.9054 26.5381C94.7321 26.8484 94.6498 27.2013 94.6681 27.5563C94.6422 27.8404 94.683 28.1266 94.7872 28.3922C94.8913 28.6578 95.056 28.8955 95.2681 29.0863C95.6828 29.4062 96.1956 29.5727 96.7191 29.5573C97.6135 29.5611 98.4871 29.2872 99.2191 28.7733C99.6328 28.4287 99.9563 27.9886 100.162 27.491C100.367 26.9934 100.449 26.4533 100.399 25.9173V24.3803Z" />
        <path d="M127.547 16.3133C128.273 16.6225 128.908 17.1136 129.389 17.7393C129.853 18.3314 130.162 19.0292 130.289 19.7703C130.402 20.5432 130.451 21.3243 130.434 22.1053L130.405 32.0723H126.169V22.0003C126.187 21.4911 126.088 20.9846 125.878 20.5203C125.688 20.1553 125.393 19.8555 125.031 19.6595C124.67 19.4635 124.258 19.3802 123.848 19.4203C123.355 19.3814 122.863 19.4972 122.439 19.7515C122.015 20.0059 121.681 20.3861 121.483 20.8393C121.248 21.4144 121.136 22.0322 121.153 22.6533V32.0683H116.989V22.6533C117.028 21.9592 116.929 21.2643 116.699 20.6083C116.519 20.2284 116.226 19.9139 115.859 19.7084C115.492 19.5029 115.071 19.4165 114.653 19.4613C114.188 19.4038 113.717 19.4813 113.295 19.6845C112.873 19.8877 112.518 20.2081 112.273 20.6073C112.017 21.2218 111.902 21.8864 111.939 22.5513V32.0683H107.746V16.2843H111.765V18.5913C112.144 17.9286 112.634 17.3359 113.214 16.8393C114.12 16.1879 115.219 15.8607 116.333 15.9103C117.37 15.8624 118.395 16.1424 119.263 16.7103C119.872 17.217 120.341 17.8711 120.626 18.6103C121.095 17.7527 121.794 17.0428 122.644 16.5603C123.526 16.1178 124.501 15.8938 125.488 15.9073C126.194 15.9092 126.894 16.0471 127.548 16.3133" />
        <path d="M138.459 10.6841V32.0681H154.659V28.2231H142.825V22.7231H153.213V19.0111H142.825V14.4711H154.141V10.6841H138.459Z" />
        <path d="M157.357 32.068H161.491V10.687H157.357V32.068Z" />
        <path d="M190.466 16.2551L187.101 27.9191L183.808 16.2551H179.166L184.836 32.0681H189.196L194.906 16.2551H190.466Z" />
        <path d="M175.188 16.5752C176.303 17.0793 177.251 17.8907 177.92 18.9142C178.584 19.8949 179.015 21.0144 179.18 22.1872C179.307 23.2284 179.353 24.2779 179.316 25.3262H167.799C167.765 25.9777 167.899 26.6271 168.189 27.2116C168.479 27.7961 168.914 28.2961 169.453 28.6632C170.141 29.075 170.932 29.2815 171.733 29.2582C172.562 29.2966 173.377 29.0397 174.033 28.5332C174.387 28.231 174.676 27.8611 174.884 27.4452H179.105C178.913 28.5365 178.374 29.5368 177.568 30.2982C176.8 31.0864 175.871 31.7003 174.845 32.0984C173.818 32.4964 172.718 32.6692 171.619 32.6052C169.592 32.632 167.619 31.9429 166.049 30.6592C164.441 29.3618 163.636 27.2515 163.636 24.3282C163.56 23.1858 163.713 22.0396 164.087 20.9575C164.461 19.8754 165.049 18.8793 165.814 18.0282C166.557 17.2954 167.442 16.7228 168.414 16.3458C169.387 15.9687 170.426 15.7953 171.468 15.8362C172.747 15.8158 174.015 16.0677 175.188 16.5752M169.002 20.1472C168.384 20.8249 167.999 21.6827 167.902 22.5952H175.025C175.022 22.1256 174.922 21.6616 174.733 21.2317C174.544 20.8019 174.269 20.4151 173.925 20.0952C173.241 19.5182 172.368 19.2147 171.473 19.2432C171.022 19.215 170.57 19.2804 170.145 19.4353C169.721 19.5902 169.333 19.8312 169.005 20.1432" />
        <path d="M206.3 16.5752C207.414 17.0793 208.362 17.8907 209.032 18.9142C209.696 19.8949 210.127 21.0144 210.292 22.1872C210.419 23.2284 210.464 24.2779 210.428 25.3262H198.912C198.877 25.9776 199.011 26.627 199.3 27.2116C199.59 27.7961 200.025 28.2961 200.565 28.6632C201.252 29.075 202.044 29.2815 202.845 29.2582C203.673 29.2966 204.488 29.0397 205.145 28.5332C205.498 28.231 205.788 27.8611 205.996 27.4452H210.217C210.024 28.5365 209.485 29.5368 208.68 30.2982C207.911 31.0864 206.982 31.7003 205.956 32.0984C204.93 32.4964 203.83 32.6692 202.731 32.6052C200.703 32.632 198.731 31.9429 197.161 30.6592C195.552 29.3618 194.748 27.2515 194.748 24.3282C194.671 23.1858 194.824 22.0396 195.198 20.9575C195.572 19.8754 196.16 18.8793 196.926 18.0282C197.668 17.2954 198.553 16.7228 199.525 16.3458C200.498 15.9687 201.537 15.7953 202.58 15.8362C203.858 15.8158 205.126 16.0677 206.3 16.5752M200.114 20.1472C199.495 20.8247 199.109 21.6826 199.014 22.5952H206.136C206.132 22.1256 206.033 21.6616 205.844 21.2317C205.654 20.8019 205.38 20.4151 205.036 20.0952C204.352 19.5182 203.478 19.2147 202.584 19.2432C202.133 19.215 201.68 19.2804 201.256 19.4353C200.831 19.5902 200.443 19.8312 200.116 20.1432" />
        <path d="M225.165 17.1622C226.201 18.0182 226.718 19.4372 226.718 21.4192V32.0682H222.482V22.4502C222.515 21.7951 222.402 21.1409 222.15 20.5352C221.932 20.1258 221.597 19.791 221.187 19.5738C220.777 19.3566 220.311 19.2669 219.85 19.3162C219.245 19.2704 218.641 19.4186 218.125 19.7394C217.61 20.0602 217.21 20.5368 216.984 21.1002C216.692 21.8682 216.556 22.6869 216.584 23.5082V32.0682H212.449V16.2842H216.449V18.5902C216.849 17.9262 217.356 17.3331 217.949 16.8352C218.887 16.1676 220.021 15.83 221.171 15.8752C222.613 15.8148 224.03 16.2712 225.165 17.1622" />
      </svg>
    </StyledLogo>
  );
};

export default Logo;
