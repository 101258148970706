import { useEffect, useState } from 'react';

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState('');
  const [prevOffset, setPrevOffset] = useState(0);

  const toggleScrollDirection = () => {
    const { scrollY } = window;
    if (scrollY > prevOffset && scrollY > 50) {
      setScrollDirection('down');
    } else if (scrollY < prevOffset && scrollY > 50) {
      setScrollDirection('up');
    } else {
      setScrollDirection('');
    }
    setPrevOffset(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleScrollDirection);
    return () => {
      window.removeEventListener('scroll', toggleScrollDirection);
    };
  });
  return scrollDirection;
};

export default useScrollDirection;
