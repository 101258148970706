/* eslint-disable jsx-a11y/media-has-caption */
import Image from 'next/image';
import { useRef, useState } from 'react';
import { Image as DatoImage } from 'react-datocms';
import styled from 'styled-components';

import useIntersection from '@/hooks/useIntersection';
import { BREAKPOINTS } from '@/theme';
import type { VideoRecord } from '@/types/Pages.types';

type TVideo = {
  content: VideoRecord;
};

const StyledVideo = styled.div`
  position: relative;
  z-index: 10;

  video {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const StyledMobileVideo = styled.div`
  display: none;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: block;
    min-height: 650px;

    .videoThumbnail {
      transition: opacity ease-in 0.5s;
      opacity: 1;

      &.active {
        opacity: 0;
      }
    }
  }
`;

const StyledDesktopVideo = styled.div`
  display: block;

  .videoThumbnail {
    transition: opacity ease-in 0.5s;
    opacity: 1;

    &.active {
      opacity: 0;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: none;
  }
`;

const StyledMuteButton = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 45px;
  z-index: 30;
`;

export const Video = ({ content }: TVideo) => {
  const [videoMuted, setVideoMuted] = useState(true);
  const { desktopPosterV2, mobilePosterV2, videoFile, mobileVideo } = content;
  const mobileVideoRef = useRef(null);
  const desktopVideoRef = useRef(null);
  // const videoFile = '/videos/desktop-showreel.mp4';
  // const mobileVideo = '/videos/mobile-showreel.mp4';

  const intersectionOptionsMobile = {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  };

  const intersectionOptionsDesktop = {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  };

  const mobileVideoIntersection = useIntersection(mobileVideoRef, intersectionOptionsMobile);
  const desktopVideoIntersection = useIntersection(desktopVideoRef, intersectionOptionsDesktop);

  const controlVideo = () => {
    if (mobileVideoRef && mobileVideoIntersection?.isIntersecting) {
      mobileVideoRef.current?.play();
    } else {
      mobileVideoRef.current?.pause();
    }

    if (desktopVideoRef && desktopVideoIntersection?.isIntersecting) {
      desktopVideoRef.current?.play();
    } else {
      desktopVideoRef.current?.pause();
    }
  };

  const muteClickHandler = () => {
    // Toggle mute
    setVideoMuted((muted) => !muted);
  };

  controlVideo();

  return (
    <StyledVideo>
      <StyledMuteButton onClick={muteClickHandler}>
        {videoMuted ? (
          <Image src="/images/unmute.svg" width={45} height={31} />
        ) : (
          <Image src="/images/mute.svg" width={43} height={31} />
        )}
      </StyledMuteButton>
      <StyledMobileVideo>
        <video
          ref={mobileVideoRef}
          id="loop"
          preload="auto"
          playsInline
          width="420"
          height="650"
          loop
          muted={videoMuted}
        >
          <source src={mobileVideo} />
        </video>
        <DatoImage
          data={mobilePosterV2.responsiveImage}
          layout="fill"
          objectFit="cover"
          className={`videoThumbnail ${mobileVideoRef && mobileVideoIntersection?.isIntersecting ? 'active' : ''}`}
        />
      </StyledMobileVideo>
      <StyledDesktopVideo>
        <video
          ref={desktopVideoRef}
          id="loop"
          preload="auto"
          playsInline
          width="1280"
          height="720"
          loop
          muted={videoMuted}
        >
          <source src={videoFile} />
        </video>
        <DatoImage
          data={desktopPosterV2.responsiveImage}
          layout="fill"
          objectFit="cover"
          className={`videoThumbnail ${desktopVideoRef && desktopVideoIntersection?.isIntersecting ? 'active' : ''}`}
        />
      </StyledDesktopVideo>
    </StyledVideo>
  );
};
