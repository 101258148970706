/* eslint-disable react/jsx-one-expression-per-line */
import Link from 'next/link';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';
import type { FooterRecord } from '@/types/Footer.types';

import { Container } from './Container';

type TFooter = {
  footer: FooterRecord;
};

const StyledFooter = styled.footer`
  color: var(--white);
  background-color: var(--grey);
  padding: 80px 0 40px;
`;

const StyledRow = styled.div<{ align?: 'flex-end'; padding?: string }>`
  display: flex;
  justify-content: space-between;
  ${(p) => p.align && `align-items: ${p.align};`}
  ${(p) => p.padding && `padding: 0 ${p.padding};`}

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    gap: 80px;

    a {
      color: white;
      text-decoration: none;
      font-weight: 400;
      font-size: 12px;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        width: 0%;
        height: 4px;
        background-color: var(--white);
        bottom: -12px;
        right: 0;
        transition: all 0.4s ease-out;
      }

      &:hover {
        &::after {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }

    &.terms {
      font-size: 12px;
    }

    &.nav {
      a {
        font-weight: 600;
        font-size: 24px;
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    ${(p) => p.padding && `padding: 0 24px;`}

    ul {
      gap: 40px;
      margin-top: 100px;

      a {
        font-size: 14px;
      }

      &.nav {
        a {
          font-size: 18px;
        }
      }

      &.terms {
        margin-top: 15px;
        font-size: 11px;
        gap: 15px;

        a {
          font-size: 11px;
        }
      }
    }

    p {
      font-size: 14px;
    }
  }
`;

const StyledHeadline = styled.div`
  width: 50%;

  h2 {
    margin: 0 0 200px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;

    h2 {
      margin: 0;
      font-size: 38px;
      line-height: 40px;
      font-weight: 600;
    }
  }
`;

const StyledColumns = styled.div`
  display: flex;
  flex: 1;
  padding-left: 40px;
  width: 100%;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding-left: 0;
    gap: 20px;
  }
`;

const StyledColumn = styled.div`
  width: 50%;
  padding-top: 140px;

  p {
    margin: 10px 0;
  }

  a {
    color: white;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding-top: 50px;
    p {
      font-size: 13px;
    }
  }
`;

const Footer = ({ footer }: TFooter) => {
  const { heading, columnOne, columnTwo, instagramUrl, linkedinUrl } = footer;
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Container>
        <StyledRow>
          <StyledHeadline>
            <h2>{heading}</h2>
          </StyledHeadline>
          <StyledColumns>
            <StyledColumn dangerouslySetInnerHTML={{ __html: columnOne }} />
            <StyledColumn dangerouslySetInnerHTML={{ __html: columnTwo }} />
          </StyledColumns>
        </StyledRow>
      </Container>
      <StyledRow align="flex-end" padding="50px">
        <ul className="nav">
          <li>
            <Link href={instagramUrl} passHref>
              <a target="_blank">Instagram</a>
            </Link>
          </li>
          <li>
            <Link href={linkedinUrl} passHref>
              <a target="_blank">LinkedIn</a>
            </Link>
          </li>
        </ul>
        <ul className="terms">
          <li>&copy; {currentYear} Team Eleven</li>
          <li>
            <Link href="/privacy-policy" passHref>
              <a target="_blank">Privacy Notice</a>
            </Link>
          </li>
        </ul>
      </StyledRow>
    </StyledFooter>
  );
};

export default Footer;
